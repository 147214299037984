// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-branding-tsx": () => import("./../../../src/pages/branding.tsx" /* webpackChunkName: "component---src-pages-branding-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-work-tsx": () => import("./../../../src/pages/our-work.tsx" /* webpackChunkName: "component---src-pages-our-work-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-templates-blog-detail-index-tsx": () => import("./../../../src/templates/BlogDetail/index.tsx" /* webpackChunkName: "component---src-templates-blog-detail-index-tsx" */),
  "component---src-templates-service-detail-index-tsx": () => import("./../../../src/templates/ServiceDetail/index.tsx" /* webpackChunkName: "component---src-templates-service-detail-index-tsx" */),
  "component---src-templates-solution-detail-index-tsx": () => import("./../../../src/templates/SolutionDetail/index.tsx" /* webpackChunkName: "component---src-templates-solution-detail-index-tsx" */)
}

